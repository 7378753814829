const config = {
  gatsby: {
    pathPrefix: '/',
    gaTrackingId: 'XXXXXX',
    siteUrl: process.env.GATSBY_ROOT_URL || 'http://localhost:3000',
  },
  env: {
    STAGE: process.env.GATSBY_STAGE || 'LOCAL',
    RECAPTCHA_SITEKEY:
      process.env.GATSBY_GOOGLE_RECAPTCHA_SITEKEY ||
      '',
    ROOT_URL: process.env.GATSBY_ROOT_URL || 'http://localhost:3000'
  },
  general: {
    email: 'hello@organism.ai',
    ockamLibraryRepo: 'https://github.com/',
    ockamWebsiteRepo: 'https://github.com/',
    markdownPath: 'src/content',
    githubProductionPath: 'tree/production',
    communityChannel: 'https://github.com/',
    twitter: 'https://twitter.com/',
    starredRepoUsername: '',
    starredRepoName: 'organism',
  },
  api: {
    githubUrl: 'https://api.github.com',
  },
  siteMetadata: {
    title: 'Organism',
    description:
      "Organism is a Systems Company. We focus on Blockchain Technologies",
  },
};

module.exports = config;
