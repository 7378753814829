// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-enterprise-js": () => import("./../../../src/pages/enterprise.js" /* webpackChunkName: "component---src-pages-enterprise-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-analysis-js": () => import("./../../../src/pages/services/analysis.js" /* webpackChunkName: "component---src-pages-services-analysis-js" */),
  "component---src-pages-services-azure-js": () => import("./../../../src/pages/services/azure.js" /* webpackChunkName: "component---src-pages-services-azure-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-influxdata-js": () => import("./../../../src/pages/services/influxdata.js" /* webpackChunkName: "component---src-pages-services-influxdata-js" */),
  "component---src-pages-services-microchip-js": () => import("./../../../src/pages/services/microchip.js" /* webpackChunkName: "component---src-pages-services-microchip-js" */),
  "component---src-pages-services-planning-js": () => import("./../../../src/pages/services/planning.js" /* webpackChunkName: "component---src-pages-services-planning-js" */),
  "component---src-pages-services-threat-js": () => import("./../../../src/pages/services/threat.js" /* webpackChunkName: "component---src-pages-services-threat-js" */),
  "component---src-templates-learn-template-js": () => import("./../../../src/templates/LearnTemplate.js" /* webpackChunkName: "component---src-templates-learn-template-js" */)
}

