import { lighten } from 'polished';

import defaultTheme from './defaultTheme';

const colors = {
  dark: '#242A31',
  background: '#fff',
  accentBackground: '#E6ECF1',
  icon: '#9DAAB6',
  heading: '#242A31',
  text: '#3C454E',
  menuText: '#7A8895',
  menuTextActive: '#242A31',
  code: '#3C454E',
  primary: '#52c7ea',
  dirtyPrimary: '#70ccea',
  accent: '#EC432D',
  accentHover: '#FF2D11',
  accentActive: '#BF3B2C',
  secondary: '#e9f7fc',
  caption: '#7A8895',
  white: '#fff',
  blogTextBackground: '#fff',
  inputBackground: '#F5F7F9',
  inputBorder: '#E3E8EE',
  inputText: '#0A1A2B',
  inputPlaceholder: '#A2B6C7',
  modalBackground: 'rgba(0,0,0,0.3)',
  modalShadow: 'rgba(60,69,78,0.8)',
  dropdownMenuBackground: '#fff',
  dropdownMenuItemHover: '#F5F7F9',
  dropdownMenuBorder: '#E6ECF1',
  dropdownMenuShadow: 'rgba(116,129,141,0.1)',
  button: {
    primaryShadow: 'rgba(82,199,234,0.4)',
    primaryActive: '#fff',
    whiteActiveBackground: '#E6E8EA',
    secondaryHover: '#00BAEA',
    secondaryActive: '#9DDFF2',
    secondaryText: '#25B8E4',
    linkText: '#7A8895',
  },
  link: {
    default: '#7A8895',
    hover: '#242A31',
    active: '#3C454E',
  },
  search: {
    resultBoxBorder: '#E6ECF1',
    resultBoxShadow: 'rgba(116,129,141,0.1)',
    resultItemHover: '#F5F7F9',
    resultText: '#A2B6C7',
    resultsCountText: '#D4DDE5',
    resultsDividerColor: '#F5F7F9',
    caption: '#A2B6C7',
  },
  tableOfContent: {
    separatorBorder: '#E6ECF1',
    item: '#7A8895',
    itemActive: '#242A31',
  },
  mobileMenuItemDivider: '#E6ECF1',
  githubStarButton: {
    icon: '#FFB900',
  },
  badge: {
    defaultText: '#A2B6C7',
    defaultBorder: '#D4DDE5',
    defaultBackground: '#F5F7F9',
  },
  sidebar: {
    background: '#fff',
    item: '#7A8895',
    itemActive: '#242A31',
    customScrollTruck: lighten(0.25, '#a4a9bf'),
    customScrollThumb: '#a4a9bf',
  },
  codeBlock: {
    background: '#E6ECF1',
  },
  table: {
    accent: '#E6ECF1',
  },
};

/* eslint-disable prefer-destructuring */
const fontSizes = Object.assign(
  [...defaultTheme.fontSizes],
  defaultTheme.fontSizes
);
fontSizes.body = fontSizes[2];
/* eslint-enable prefer-destructuring */

const lightTheme = {
  ...defaultTheme,
  fontSizes,
  name: 'light',
  colors: {
    ...defaultTheme.colors,
    ...colors,
  },
};

export default lightTheme;
